import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { TicketListAddItem } from './add';
import { TicketListCurrentItem } from './current';
import { TicketListOtherItems } from './others';
export const TicketList = (props) => {
  const cartItems = props.cart.cartItems || {},
    cartSeatItems = {}, // assigned seating items in the cart
    otherSeatItems = {}, // assigned seating items not in the cart
    cartOtherItems = {}; // either general admission cart items or items from a different event

  const currentEventSeatItemIds = Object.keys(props.possibleItems);

  for (const itemId of Object.keys(cartItems)) {
    const cartItem = cartItems[itemId];
    if (cartItem.s && currentEventSeatItemIds.includes(itemId)) {
      cartSeatItems[itemId] = cartItem;
    } else {
      cartOtherItems[itemId] = cartItem;
    }
  }

  const cartItemIds = Object.keys(cartItems);

  for (const itemId of currentEventSeatItemIds) {
    if (cartItemIds.indexOf(itemId) > -1) continue;
    otherSeatItems[itemId] = props.possibleItems[itemId];
  }

  const currentItems = map(cartSeatItems, (cartSeatItem, itemId) => {
    const ticketType = props.possibleItems[itemId];

    return (
      <TicketListCurrentItem
        key={itemId}
        itemId={itemId}
        {...ticketType}
        itemSeats={cartSeatItem.s}
        possibleItems={props.possibleItems}
        allowSelection={props.allowSelection}
        handleUpdateItem={props.handleUpdateItem}
        handleRemoveSeat={props.handleRemoveSeat}
        busy={props.busy}
        selectedQID={props.selectedQID}
      />
    );
  });

  const handleClose = (event) => {
    event.preventDefault();
    props.handleClose();
  };

  const renderBusy = () => {
    if (props.busy) return false;
    if (props.hideCheckout) return false;
    return (
      <div className="seatmap-cart-close">
        <p>
          <a href="#" onClick={handleClose}>
            Save and close
          </a>{' '}
          to find other tickets.
        </p>
      </div>
    );
  };

  return (
    <div className="seatmap-ticket-list">
      {currentItems}
      <TicketListAddItem
        items={otherSeatItems}
        handleUpdateItem={props.handleUpdateItem}
        busy={props.busy}
      />
      <TicketListOtherItems items={cartOtherItems} />
      {renderBusy()}
    </div>
  );
};

TicketList.propTypes = {
  allowSelection: PropTypes.bool,
  busy: PropTypes.bool,
  cart: PropTypes.object,
  possibleItems: PropTypes.object.isRequired,
  handleRemoveSeat: PropTypes.func,
  handleUpdateItem: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedQID: PropTypes.string,
  hideCheckout: PropTypes.bool,
};

TicketList.defaultProps = {
  possibleItems: {},
  cart: {},
};
