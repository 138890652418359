import { createConsumer } from '@rails/actioncable';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { reportError } from '../common/errorReporting';
import getCSRFToken from '../common/getCSRFToken';
import { CART_UPDATED_EVENT_TYPE } from '../frontend/cart';
import CheckoutButton from './CheckoutButton';
import LargeCloseButton from './LargeCloseButton';
import PublicSeatmapMapContainer from './PublicSeatmapMapContainer';
import { SeatmapHeader } from './header';
import seatDataToEmit from './seatDataToEmit';
import { TicketList } from './ticket_list';
const SEAT_CLASSES = {
  a: 'seatmap-seat seatmap-available',
  s: 'seatmap-seat seatmap-sold',
  p: 'seatmap-seat seatmap-pending',
  c: 'seatmap-seat seatmap-in-cart',
};

const ACTIVE_CLASS = 'seatmap-seat-active';

const UNAVAILABLE_CLASS = 'seatmap-seat seatmap-unavailable';

const OVERLAY_ID = 'seatmap-overlay';

const post = function (endpoint, method, params) {
  params['_method'] = method;

  return $.ajax({
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
      return;
    },
    type: method,
    url: endpoint,
    data: params,
    xhrFields: {
      withCredentials: true,
    },
    dataType: 'json',
  });
};

const INITIAL_STATE = {
  eventTitle: '',
  cartSeats: null,
  cart: undefined,
  loadingData: true,
  loadingMap: true,
  busy: false,
  allowSelection: false,
  errors: [],
  items: {},
  seats: {},
  closed: false,
  currentView: 'map',
  selectedQID: undefined,
  ud: 0, // last updated at
};

export default class Seatmap extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpdateItem = this.handleUpdateItem.bind(this);
    this.handleRemoveSeat = this.handleRemoveSeat.bind(this);
    this.handleAddSeat = this.handleAddSeat.bind(this);
    this.handleMapReady = this.handleMapReady.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePostError = this.handlePostError.bind(this);
    this.handleViewToggle = this.handleViewToggle.bind(this);
    this.onSelectQID = this.onSelectQID.bind(this);
    this.actioncable = null;
    this.websocketSubscriptions = [];
    this.state = Object.assign({}, INITIAL_STATE);
  }

  componentDidMount() {
    const xhr = $.ajax({
      type: 'GET',
      url: this.props.endpoint,
      dataType: 'json',
      headers: {
        'X-CSRF-Token': getCSRFToken(),
        Accept: 'application/json; charset=utf-8',
      },
      xhrFields: {
        withCredentials: true,
      },
    });

    xhr.done((data) => {
      this.onSeatData(data);
      this.connectWebsocket();
      this.emitEvent('seatmap:ready', {});
    });
  }

  emitEvent(eventType, payload) {
    if (this.props.emitEventCallback) {
      this.props.emitEventCallback(eventType, payload);
      console.log('emitEvent', eventType, payload);
    }
  }

  // reload the JS in case things have changed -- ideally websockets would have
  // picked everything up but we want to be sure
  reopen() {
    const parentNode = document.getElementById(OVERLAY_ID);
    parentNode.classList.remove('closed'); // the containing overlay outside of React
    this.setState(Object.assign({}, INITIAL_STATE, { loadingMap: false }));
    this.componentDidMount();
  }

  resetView() {
    this.mapContainer?.reset();
    this.mapContainer?.resetView();
  }

  componentWillUnmount() {
    this.emitEvent('seatmap:unloaded');
    this.websocketSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // SVG isn't controlled by React -- we just inject the map and use regular DOM
  // methods to update it
  // TODO: check if state.seats and state.loadingMap actually changed
  componentDidUpdate() {
    if (this.state.busy && this.mapContainer) this.mapContainer.reset();
    this.updateSeatStyles();
  }

  connectWebsocket() {
    const { eventID } = this.props;

    // To debug websockets uncomment this:
    // ActionCable.logger.enabled = true

    // already connected
    if (this.websocketSubscriptions[eventID]) return;

    if (!this.actioncable) this.actioncable = createConsumer();

    // ideally we'd be able to pull any backlog from the `since` timestamp here
    this.websocketSubscriptions[eventID] = this.actioncable.subscriptions.create(
      {
        channel: 'SeatsChannel',
        id: eventID,
      },
      {
        connected: () => console.info('SeatsChannel connected'),
        received: (seatData) => {
          console.info('onSeatData', Object.assign({}, seatData));
          this.onSeatData(seatData);
        },
      },
    );
  }

  updateSeatStyles() {
    if (this.state.loadingMap) return;
    if (this.state.loadingData) return;

    Object.keys(this.state.seats).forEach((qid) => {
      const seatData = this.state.seats[qid],
        seatNode = document.getElementById(`seatmap-${qid}`),
        seatClass = SEAT_CLASSES[seatData.s] || UNAVAILABLE_CLASS;

      if (!seatNode) return;

      if (seatNode.className.baseVal.indexOf(ACTIVE_CLASS) > -1) {
        // leave the active seat as is
        return;
      }

      if (seatNode.className.baseVal != seatClass) {
        seatNode.className.baseVal = seatClass;
      }
    });
  }

  handleMapReady() {
    this.setState({ loadingMap: false });
  }

  handleClose() {
    const parentNode = document.getElementById(OVERLAY_ID);
    parentNode.classList.add('closed'); // the containing overlay outside of React
    if (this.mapContainer) this.mapContainer.reset();
    this.setState(Object.assign({}, INITIAL_STATE, { closed: true }));
  }

  handlePostError(xhr, textStatus, errorThrown) {
    console.error('handlePostError', xhr, textStatus, errorThrown);
    reportError(errorThrown);
    this.emitEvent('seatmap:postError');
    // TODO: I18n
    this.setState({
      busy: false,
      errors: [
        'Sorry, something went wrong while we were trying to reserve your seats. Please try again.',
      ],
    });
  }

  handleViewToggle(nextView) {
    this.setState({ currentView: nextView });
  }

  // data.seats = { qid: seatData }
  onSeatData(data) {
    if (!data.cart && this.props.cart) {
      data.cart = this.props.cart;
    }

    const fromInitialData = this.state.loadingData;

    data.cartSeats = this.generateCartSeats(data);
    data.seats = this.mergeSeatDefinitions(data);
    data.loadingData = false;
    data.selectedQID = undefined;

    // promote errors from the cart for easier querying
    if (data.cart && data.cart.errors) {
      data.errors = data.cart.errors;
      delete data.cart.errors;
    }

    this.setState(data);

    // NOTIFY THE WEB FRONT END
    // don't fire if this comes from websockets (with no cart) or on initial load
    if (data.cart && !fromInitialData) {
      const event = new CustomEvent(CART_UPDATED_EVENT_TYPE, { detail: data.cart });
      document.dispatchEvent(event);
    }

    // NOTIFY THE MOBILE APP WEBVIEW
    this.emitEvent('seatmap:orderUpdated', data.cart);

    if (this.props.afterUpdateCallback) {
      this.props.afterUpdateCallback(data);
    }
  }

  mergeSeatDefinitions(data) {
    const cartQidWithItemIds = {},
      seats = this.state.seats || {};

    if (data.cartSeats) {
      Object.keys(data.cartSeats).forEach((itemId) => {
        const itemSeats = data.cartSeats[itemId];
        Object.keys(itemSeats).forEach((qid) => {
          cartQidWithItemIds[qid] = itemId;
        });
      });
    }

    // now merge in updated statuses EXCEPT for our current cart seats which we
    // assign the current cart status and cartItemId
    //
    // we use the cartItemId in the SeatTooltip to show the current item type
    Object.keys(data.seats).forEach((qid) => {
      const cartItemId = cartQidWithItemIds[qid];

      if (!seats[qid]) seats[qid] = data.seats[qid];

      if (cartItemId) {
        // found a seat that's in the current cart
        seats[qid].s = 'c';
        seats[qid].cartItemId = cartItemId;
      } else {
        // use the status from the JSON response
        seats[qid].s = data.seats[qid].s;
      }
    });

    return seats;
  }

  generateCartSeats(data) {
    // no cart was provided so this update came from the websocket subscription
    // leave the existing cart data untouched
    if (!data.cart) return this.state.cartSeats;

    const cartSeats = {};
    if (!data.cart.cartItems) return cartSeats;

    for (const itemId of Object.keys(data.cart.cartItems)) {
      const cartItem = data.cart.cartItems[itemId];
      if (!cartItem.s) continue;

      if (!cartSeats[itemId]) cartSeats[itemId] = {};

      for (const qid of Object.keys(cartItem.s)) {
        const cartSeat = cartItem.s[qid];
        // cartSeat.cartItemId = itemId; // make lookups easier
        cartSeats[itemId][qid] = cartSeat;
      }
    }

    return cartSeats;
  }

  // in the backend we need to post all items, not just the changed ones,
  // to the order adjuster
  buildBaseCartForPosting() {
    const params = { items: {}, since: this.state.ud };
    if (!this.props.includeRestOfCart) return params;

    Object.keys(this.state.cart.cartItems).forEach((itemId) => {
      const item = this.state.cart.cartItems[itemId];

      params['items'][itemId] = {};

      if (item.s) {
        // keep any selected QIDs
        params['items'][itemId]['qids'] = Object.keys(item.s).join(',');
      } else {
        params['items'][itemId]['quantity'] = item.q;
      }
    });

    return params;
  }

  handleUpdateItem(itemId, quantity) {
    if (this.state.busy) return false;
    this.setState({ busy: true, errors: [] });
    const params = this.buildBaseCartForPosting();

    if (!params['items'][itemId]) params['items'][itemId] = {};
    params['items'][itemId]['quantity'] = quantity;

    post(this.props.endpoint, 'PUT', params)
      .done((data) => {
        data.busy = false;
        this.onSeatData(data);
      })
      .fail(this.handlePostError);
  }

  handleAddSeat(itemId, qid) {
    if (this.state.busy) return false;
    if (!this.state.allowSelection) return false;
    this.setState({ busy: true, errors: [] });

    const requestedSeats = Object.assign({}, this.state.cartSeats);
    const params = this.buildBaseCartForPosting();

    // to support swapping seat item-types we need to check if this qid had a previously assigned itemId and remove it
    for (const requestedItemId of Object.keys(requestedSeats)) {
      delete requestedSeats[requestedItemId][qid];
    }

    if (!requestedSeats[itemId]) requestedSeats[itemId] = {};
    requestedSeats[itemId][qid] = {}; // no need to look up seat data here

    Object.keys(requestedSeats).forEach((itemId) => {
      if (!params['items'][itemId]) params['items'][itemId] = {};
      params['items'][itemId]['qids'] = Object.keys(requestedSeats[itemId]).join(',');
    });

    post(this.props.endpoint, 'PUT', params)
      .done((data) => {
        data.busy = false;
        this.onSeatData(data);
        this.emitEvent('log', {
          method: 'handleAddSeat',
          httpEndpoint: this.props.endpoint,
          httpMethod: 'PUT',
          httpParams: params,
          success: true,
        });
      })
      .fail(this.handlePostError);
  }

  handleRemoveSeat(qid) {
    if (this.state.busy) return false;
    if (!this.state.allowSelection) return false;
    this.setState({ busy: true, errors: [] });

    const params = this.buildBaseCartForPosting();

    for (const itemId of Object.keys(this.state.cartSeats)) {
      const itemSeats = this.state.cartSeats[itemId],
        itemQIDs = Object.keys(itemSeats),
        index = itemQIDs.indexOf(qid);

      if (index > -1) itemQIDs.splice(index, 1);
      if (!params['items'][itemId]) params['items'][itemId] = {};
      params['items'][itemId]['qids'] = itemQIDs.join(',');
    }

    post(this.props.endpoint, 'PUT', params)
      .done((data) => {
        data.busy = false;
        this.onSeatData(data);
        this.emitEvent('log', {
          method: 'handleRemoveSeat',
          httpEndpoint: this.props.endpoint,
          httpMethod: 'PUT',
          httpParams: params,
          success: true,
        });
      })
      .fail(this.handlePostError);
  }

  clearErrors() {
    this.setState({ errors: [] });
  }

  onSelectQID(qid) {
    if (this.state.selectedQID === qid) return;
    this.setState({ selectedQID: qid });

    if (qid) {
      this.emitEvent(
        'seatmap:selectedSeat',
        seatDataToEmit(qid, this.state.seats[qid], this.state.possibleItems),
      );
    } else {
      this.emitEvent('seatmap:deselectedSeat', {
        qid: null,
      });
    }
  }

  renderSuspenseLoading() {
    return this.renderTakeover([<p key="loading">Loading...</p>], false);
  }

  renderTakeover(messages, renderCloseButton) {
    if (!messages || messages.length < 1) return false;

    const onCloseClick = (event) => {
      event.preventDefault();
      this.clearErrors();
    };

    const closeButton = renderCloseButton ? (
      <div className="controls">
        <a className="close" onClick={(event) => onCloseClick(event)}>
          Close
        </a>
      </div>
    ) : (
      false
    );

    return (
      <div className="seatmap-takeover">
        <div className="seatmap-takeover-content">
          {messages}
          {closeButton}
        </div>
      </div>
    );
  }

  lockUI() {
    return this.state.busy || this.loading();
  }

  loading() {
    return this.state.loadingData || this.state.loadingMap;
  }

  // we override the currentView when there are errors so that they are always
  // displayed on mobile -- this doesn't have any effect on desktop
  getCurrentView() {
    return this.state.errors.length > 0 ? 'map' : this.state.currentView;
  }

  renderBody() {
    let messages = [];

    if (this.state.errors) {
      messages = this.state.errors.map((error) => (
        <p key={error} className="seatmap-error">
          {error}
        </p>
      ));
    }
    const renderCloseButton = !this.lockUI();
    if (this.state.busy) messages.push(<p key="busy">Working...</p>);
    if (this.loading()) messages.push(<p key="loading">Loading...</p>);
    return this.renderTakeover(messages, renderCloseButton);
  }

  renderCart() {
    if (this.props.hideCart) return null;
    if (this.loading()) return <div className="seatmap-cart" />;

    const cart = this.state.cart || { totalItems: 0, remaining: null };
    const disableCheckout = this.state.busy || cart.totalItems < 1;

    const checkoutButton = this.props.hideCheckout ? (
      <LargeCloseButton handleClose={this.handleClose} />
    ) : (
      <CheckoutButton
        disabled={disableCheckout}
        busy={this.state.busy}
        newCheckoutURL={this.props.newCheckoutURL}
        remaining={cart.remaining}
        totalItems={cart.totalItems}
      />
    );

    return (
      <div className="seatmap-cart" id="seatmap-cart">
        <TicketList
          possibleItems={this.state.possibleItems}
          cart={this.state.cart}
          allowSelection={this.state.allowSelection}
          handleUpdateItem={this.handleUpdateItem}
          handleRemoveSeat={this.handleRemoveSeat}
          handleClose={this.handleClose}
          busy={this.state.busy}
          selectedQID={this.state.selectedQID}
          hideCheckout={this.props.hideCheckout}
        />
        {checkoutButton}
      </div>
    );
  }

  renderHeader() {
    if (this.props.hideHeader) return null;

    return (
      <SeatmapHeader
        eventTitle={this.state.eventTitle}
        eventSubtitle={this.state.eventSubtitle}
        handleClose={this.handleClose}
        handleViewToggle={this.handleViewToggle}
        currentView={this.state.currentView}
      />
    );
  }

  render() {
    const klass = ['seatmap-root', `seatmap-${this.getCurrentView()}-view`];

    if (this.props.rootClass) {
      klass.push(this.props.rootClass);
    }

    return (
      <div className={klass.join(' ')}>
        {this.renderHeader()}
        <div className="seatmap-body">
          <Suspense fallback={this.renderSuspenseLoading()}>
            <div className="seatmap-container">
              <PublicSeatmapMapContainer
                path={this.props.svg}
                handleMapReady={this.handleMapReady}
                seats={this.state.seats}
                possibleItems={this.state.possibleItems}
                allowSelection={this.state.allowSelection}
                handleAddSeat={this.handleAddSeat}
                handleRemoveSeat={this.handleRemoveSeat}
                onSelectQID={this.onSelectQID}
                hideTooltips={this.props.hideTooltips}
                ref={(instance) => {
                  this.mapContainer = instance;
                }}
              />
              {this.renderBody()}
            </div>
          </Suspense>
          {this.renderCart()}
        </div>
      </div>
    );
  }
}

Seatmap.propTypes = {
  endpoint: PropTypes.string.isRequired,
  newCheckoutURL: PropTypes.string,
  svg: PropTypes.string.isRequired,
  eventID: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
  hideCheckout: PropTypes.bool,
  hideCart: PropTypes.bool,
  hideTooltips: PropTypes.bool,
  afterUpdateCallback: PropTypes.func,
  emitEventCallback: PropTypes.func,

  // optional override used by the admin order adjuster
  cart: PropTypes.object,

  // include unrelated cart items when posting? used by the admin order adjuster
  includeRestOfCart: PropTypes.bool,

  rootClass: PropTypes.string,
};

Seatmap.defaultProps = {
  hideCheckout: false,
  includeRestOfCart: false,
};
