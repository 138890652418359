import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from './Tooltip';

const SEAT_STATUSES = {
  a: 'Available',
  s: 'Unavailable',
  p: 'Unavailable',
  u: 'Unavailable',
  c: 'In your order',
};

// Main tooltip for adding or remove seats for the public
export class SeatTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddSeat = this.handleAddSeat.bind(this);
    this.handleRemoveSeat = this.handleRemoveSeat.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.state = {
      selectedItemId: this.getSelectedItemId(props.seatData),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.seat != this.props.seat) {
      this.setState({
        selectedItemId: this.getSelectedItemId(nextProps.seatData),
      });
    }
  }

  getSelectedItemId(seatData) {
    if (!seatData || !seatData.i) return null;
    return seatData.cartItemId ? seatData.cartItemId : seatData.i[0];
  }

  handleAddSeat() {
    this.props.handleAddSeat(this.state.selectedItemId);
  }

  handleRemoveSeat() {
    this.props.handleRemoveSeat();
  }

  handleItemChange(event) {
    this.setState({ selectedItemId: event.currentTarget.value });
  }

  cartItemChanged() {
    if (!this.props.seatData.cartItemId) return false;
    return this.props.seatData.cartItemId != this.state.selectedItemId;
  }

  itemDescription(itemId, withPrice = true) {
    const item = this.props.possibleItems[itemId];
    if (!item) {
      console.warn('could not load itemDescription for itemId', itemId);
      return false;
    }
    return withPrice ? `${item.title} - ${item.price}` : item.title;
  }

  renderMulti(itemIds) {
    return itemIds.map((itemId) => {
      return (
        <div key={itemId}>
          <label>
            <input
              type="radio"
              name="itemId"
              className="radio"
              checked={this.state.selectedItemId == itemId}
              value={itemId}
              onChange={this.handleItemChange}
            />
            {this.itemDescription(itemId, true)}
          </label>
        </div>
      );
    });
  }

  renderBody(hasAction) {
    const { seatData } = this.props;
    if (!seatData.i) {
      console.warn('no items for seatData', seatData);
      return false;
    }

    // skip the body if the seat isn't available or in the current order
    if (seatData.s !== 'a' && seatData.s !== 'c') {
      return false;
    }

    if (seatData.i.length === 1) {
      return this.itemDescription(seatData.i[0], false);
    }

    if (hasAction) {
      return <div>{this.renderMulti(seatData.i)}</div>;
    }

    // not able to add to cart so just output text
    const text = seatData.i.map((itemId) => {
      return <div key={itemId}>{this.itemDescription(itemId, false)}</div>;
    });

    return <div>{text}</div>;
  }

  render() {
    const { seat, seatData, handleClose, allowSelection } = this.props;

    if (!seat) return false;

    if (!seatData) {
      return (
        <Tooltip
          seat={seat}
          currentTransform={this.props.currentTransform}
          title="Not available"
          handleClose={handleClose}
        />
      );
    }

    let action, onAction;

    if (allowSelection) {
      if (seatData.s === 'a') {
        action = 'Add to order';
        onAction = this.handleAddSeat;
      } else if (seatData.s === 'c') {
        if (this.cartItemChanged()) {
          action = 'Update your order';
          onAction = this.handleAddSeat;
        } else {
          action = 'Remove from your order';
          onAction = this.handleRemoveSeat;
        }
      }
    }
    return (
      <Tooltip
        seat={seat}
        currentTransform={this.props.currentTransform}
        title={seatData.d}
        subtitle={SEAT_STATUSES[seatData.s]}
        action={action}
        onAction={onAction}
        handleClose={handleClose}
        body={this.renderBody(onAction)}
      />
    );
  }
}

SeatTooltip.propTypes = {
  seat: PropTypes.instanceOf(Element), // raw <circle> DOM/SVG element
  seatData: PropTypes.object,
  possibleItems: PropTypes.object,
  allowSelection: PropTypes.bool,
  handleAddSeat: PropTypes.func.isRequired,
  handleRemoveSeat: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  currentTransform: PropTypes.object,
};

SeatTooltip.defaultProps = {
  possibleItems: {},
};
