import 'whatwg-fetch';
import { JsonResponse } from './types';
import getCSRFToken from '../../common/getCSRFToken';
import { CART_UPDATED_EVENT_TYPE } from '../../frontend/cart';
import { CartData } from '../../frontend/types';

export function fetchData(endpoint: string): Promise<JsonResponse> {
  return new Promise<JsonResponse>((resolve, reject) => {
    fetch(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
      // ensure we send the session ID across subdomains
      // https://medium.com/@Nicholson85/handling-cors-issues-in-your-rails-api-120dfbcb8a24
      credentials: 'include',
    })
      .then(function (response) {
        response.json().then((json: JsonResponse) => resolve(json));
      })
      .catch((error) => reject(error));
  });
}

export function postData(
  updateEndpoint: string,
  itemID: string,
  quantity: number,
  selectedComponents: Array<string>,
) {
  return new Promise<CartData>((resolve, reject) => {
    const items = {};
    items[itemID] = {
      quantity,
      combo_options: selectedComponents.map((componentID) => {
        return { item_id: componentID };
      }),
    };

    return fetch(updateEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
      body: JSON.stringify({ items }),
      // ensure we send the session ID across subdomains
      // https://medium.com/@Nicholson85/handling-cors-issues-in-your-rails-api-120dfbcb8a24
      credentials: 'include',
    })
      .then(function (response) {
        response.json().then((data: CartData) => {
          const event = new CustomEvent<CartData>(CART_UPDATED_EVENT_TYPE, { detail: data });
          document.dispatchEvent(event);
          resolve(data);
        });
      })
      .catch((error) => reject(error));
  });
}
