import React, { Component } from 'react';
import PropTypes from 'prop-types';

const formatSeconds = (time) => {
  const minutes = Math.floor(time / 60);
  let seconds = time - minutes * 60;
  if (seconds <= 9) seconds = `0${seconds}`;
  return `${minutes}:${seconds}`;
};

export default class CheckoutButton extends Component {
  constructor(props) {
    super(props);
    this.maybeStartTicks = this.maybeStartTicks.bind(this);
    this.tick = this.tick.bind(this);
    this.state = {
      remaining: props.remaining,
    };
  }

  componentDidMount() {
    this.maybeStartTicks();
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // don't update remaining when we're in the middle of an API call since it
    // will be the old value
    if (nextProps.busy) return;

    this.setState(
      () => {
        return { remaining: nextProps.remaining };
      },
      () => {
        this.maybeStartTicks();
      },
    );
  }

  maybeStartTicks() {
    this.clearInterval();
    if (this.state.remaining) {
      this.interval = setInterval(this.tick, 1000);
    }
  }

  tick() {
    if (this.state.remaining < 1) {
      this.clearInterval();
      this.setState({ remaining: 0 });
    }

    this.setState((prevState) => {
      return { remaining: prevState.remaining - 1 };
    });
  }

  clearInterval() {
    clearInterval(this.interval);
    delete this.interval;
  }

  renderCountdown() {
    const { remaining } = this.state;
    if (!remaining) return false;
    if (remaining < 0) return false;

    const classNames = ['cart-countdown'];
    if (remaining <= 60) {
      classNames.push('danger');
    }

    return <span className={classNames.join(' ')}>{formatSeconds(remaining)}</span>;
  }

  renderQuantity() {
    const { totalItems } = this.props;
    if (totalItems < 1) return false;

    return <span className="cart-quantity cart-quantity-count">{totalItems}</span>;
  }

  renderBody() {
    const body = (
      <span className="nav-item-link-text">
        Checkout
        {this.renderQuantity()} {this.renderCountdown()}
      </span>
    );

    if (this.props.disabled) {
      return <div className="btn-store disabled">{body}</div>;
    }

    return (
      <a className="btn-store" href={this.props.newCheckoutURL} rel="nofollow">
        {body}
      </a>
    );
  }

  render() {
    return (
      <div className="nav-item-checkout checkout-with-status-btn seatmap-checkout">
        {this.renderBody()}
      </div>
    );
  }
}

CheckoutButton.propTypes = {
  disabled: PropTypes.bool,
  busy: PropTypes.bool,
  remaining: PropTypes.number,
  newCheckoutURL: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
};
