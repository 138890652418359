import { reportWarning } from '../common/errorReporting';
import getCSRFToken from '../common/getCSRFToken';
import { IPretokenizedCardData } from './types';

export type ValidationBeforeCheckoutResponse = {
  error: string | null;
  code: string | null;
  redirect_url?: string | null;
};

function validateCheckoutBeforeConfirming(
  validatePath: string,
  cardData: IPretokenizedCardData,
): Promise<ValidationBeforeCheckoutResponse> {
  const xhr: JQuery.jqXHR<void> = $.ajax({
    type: 'POST',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
    },
    data: { checkout: cardData },
    dataType: 'json',
    url: validatePath,
    headers: {
      Accept: 'application/json; charset=utf-8',
    },
    xhrFields: {
      withCredentials: true,
    },
  });

  return new Promise<ValidationBeforeCheckoutResponse>((resolve, reject) => {
    xhr.then(function () {
      resolve({ error: null, code: 'ok' });
    });

    xhr.fail(function (response) {
      const data = response.responseJSON as ValidationBeforeCheckoutResponse;

      if (!data) {
        reportWarning('validateCheckoutBeforeConfirming', 'no data in response', response);
        reject({ error: window.Tickit_Checkout_i18n.something_went_wrong });
        return;
      }

      if (!data.error) {
        data.error = window.Tickit_Checkout_i18n.something_went_wrong;
      }
      reject(data);
    });
  });
}

export default validateCheckoutBeforeConfirming;
