// TODO replace EventSalesReport/Select.js with DocumentEventHandler.js

// React effect to handle closing an element when the user clicks outside of the element
//
// containerRef is created via useRef in the parent component
const handleDocumentClick = (isOpen, containerRef, setOpen) => {
  const onDocumentClick = (event) => {
    if (!containerRef.current) return;
    if (containerRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const onDocumentKeydown = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode != 27) return;
    if (!containerRef.current) return;
    if (containerRef.current.contains(event.target)) return;
    setOpen(false);
  };

  // mousedown is required so that cell.js mousedown doesn't beat this handler,
  // onclick would be too late and cell.js will trigger a new render causing this to rerun
  // with unexpected behaviour

  if (isOpen) {
    document.addEventListener('mousedown', onDocumentClick, true);
    document.addEventListener('keydown', onDocumentKeydown, true);
  }

  return () => {
    document.removeEventListener('mousedown', onDocumentClick);
    document.removeEventListener('keydown', onDocumentKeydown);
  };
};

export default handleDocumentClick;
