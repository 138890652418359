import { trackSocial, logOrderActivity } from './analytics';

export const SCRIPT_URL = 'https://platform.twitter.com/widgets.js';

export default function () {
  // already initialized?
  if (window.__twitterIntentHandler) return;

  const links = $('.tweet-prompt-link');
  if (links.length > 0) $.getScript(SCRIPT_URL);

  $('.tweet-prompt-link[href]').on('click', function (e) {
    // from https://dev.twitter.com/docs/intents (Limited Dependencies)
    const windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes';
    const width = 550;
    const height = 420;
    const winHeight = screen.height;
    const winWidth = screen.width;

    const left = Math.round(winWidth / 2 - width / 2);
    let top = 0;

    if (winHeight > height) {
      top = Math.round(winHeight / 2 - height / 2);
    }

    window.open(
      this.href,
      'intent',
      windowOptions + ',width=' + width + ',height=' + height + ',left=' + left + ',top=' + top,
    );
    e.returnValue = false;
    e.preventDefault && e.preventDefault();
    logOrderActivity('started-tweet');
    trackSocial('twitter', 'tweet', this.href);
    return false;
  });
}
