let activityLogPath: string;

export const logOrderActivity = (activity: string, params?: object) => {
  try {
    if (!activityLogPath) {
      const link: HTMLAnchorElement = document.querySelector('.order-activity-log-path');

      if (link) {
        activityLogPath = link.dataset.href;
      }
    }
    $.post(activityLogPath, { activity, details: params });
  } catch (error) {
    console.error(error);
  }
};

export function trackEvent(category: string, action: string, opt_label?: any, opt_value?: any) {
  try {
    if (window.ga) {
      window.ga('tickit.send', 'event', category, action, opt_label, opt_value);
    }

    if (window._gaq) {
      console.info('found _gaq');
      window._gaq.push(['_trackEvent', category, action, opt_label, opt_value]);
    }
  } catch (error) {
    console.error(error);
  }
}

export function trackAnalyticsV4Event(
  eventName: Gtag.EventNames,
  eventParams?: Gtag.CustomParams | Gtag.EventParams,
) {
  if (!window.gtag) return;
  window.gtag('event', eventName, eventParams);
}

export function trackSocial(site: string, action: string, url?: string) {
  try {
    if (window.ga) {
      window.ga('tickit.send', 'social', site, action, url);
    }
  } catch (error) {
    console.error(error);
  }
}

export const googleAnalyticsV4Params = (): Gtag.EventParams => {
  try {
    const value = parseFloat($('.review-grand-total').attr('data-amount'));
    const currency = $('.review-grand-total').attr('data-currency');
    return { currency, value };
  } catch (error) {
    console.warn(error);
    return {};
  }
};
