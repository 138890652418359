import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

export const TicketListOtherItems = (props) => {
  const descriptions = map(props.items, (item, itemId) => {
    const label = item.d ? `${item.t} – ${item.d}` : `${item.t} x ${item.q}`;
    return <li key={itemId}>{label}</li>;
  });

  if (descriptions.length < 1) return false;

  return (
    <div className="seatmap-cart-others">
      <h4 className="heading">Also in your order&hellip;</h4>
      <ul>{descriptions}</ul>
    </div>
  );
};

TicketListOtherItems.propTypes = {
  items: PropTypes.object.isRequired,
};

TicketListOtherItems.defaultProps = {
  items: {},
};
