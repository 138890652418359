let form = undefined;
let resalePreviewEndpoint = undefined;
let resalePreviewContainer = undefined;

const updateResalePreview = () =>
  $.get(
    resalePreviewEndpoint,
    form.serialize(),
    (data) => resalePreviewContainer.html(data),
    'html',
  );

const initIndivisibleTransfers = () =>
  // parent checkbox triggers children on the ticket transfer form
  form.find('input[data-trigger-indivisible-grouping]').change(function () {
    const trigger = $(this);
    const grouping = trigger.attr('data-trigger-indivisible-grouping');
    const checkboxes = form.find(`input[data-indivisible-grouping=${grouping}]`);
    if (trigger.prop('checked')) {
      checkboxes.prop('checked', true);
    } else {
      checkboxes.prop('checked', false);
    }
  });

const initResalePreview = function () {
  form.on(
    'click',
    '.transfers-page-resale-preview [data-behavior=toggle-details]',
    function (event) {
      event.preventDefault();
      const preview = $(this).closest('.transfers-page-resale-preview');
      preview.toggleClass('show-details');
    },
  );

  resalePreviewEndpoint = form.attr('data-preview-href');
  if (!resalePreviewEndpoint) return;

  resalePreviewContainer = form.find('.transfers-page-resale-previews-container');
  if (!resalePreviewContainer.length) return;

  form.find('.select-tickets-resale-controls input').change(() => updateResalePreview());
};

export default function init() {
  form = $('.transfers-page-form');
  if (!form.length) return;

  initIndivisibleTransfers();
  initResalePreview();
}
