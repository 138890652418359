import React, { useEffect, useState, StrictMode } from 'react';
import { fetchData } from './api';
import MultipackBuilder from './index';
import { LoadingState } from '../../frontend/types';
import { MultipackJsonResponse } from './types';
import Busy from './Busy';
interface Props {
  multipackEndpoint: string;
  updateEndpoint: string;
  itemID: string;
  quantity?: number | undefined;
  handleClose: () => void;
}
export default function Loader(props: Props) {
  const { multipackEndpoint, itemID, quantity } = props;
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Loading);
  const [builderData, setBuilderData] = useState<any>(null);

  useEffect(() => {
    async function load() {
      const data = await fetchData(multipackEndpoint);
      const itemData: MultipackJsonResponse = data?.multipacks[itemID];
      if (!itemData) throw new Error(`Multipack item ${itemID} not found in data`);
      if (quantity) itemData.qty = quantity;
      setBuilderData(itemData);
      setLoadingState(LoadingState.Ready);
    }

    load();
  }, [multipackEndpoint, quantity]);

  const title = window.Tickit_Checkout_i18n.select_multipack_components;

  if (loadingState === LoadingState.Loading) {
    return (
      <Busy titleLabel={title}>
        <p>Loading...</p>
      </Busy>
    );
  }

  if (builderData) {
    return (
      <StrictMode>
        <MultipackBuilder
          {...builderData}
          titleLabel={title}
          addToCartLabel={window.Tickit_Checkout_i18n.add_to_cart}
          cancelLabel={window.Tickit_Checkout_i18n.cancel}
          handleClose={props.handleClose}
          updateEndpoint={props.updateEndpoint}
        />
      </StrictMode>
    );
  }
}
