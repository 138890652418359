import jQuery from 'jquery';
import getCSRFToken from '../common/getCSRFToken';
import { trackEvent } from './analytics';
import { createErrorFromJSON, createErrorFromXHR } from './checkoutErrors';
import getFormStringValue from './getFormStringValue';
import { ICardData, ITokenizeResult } from './types';
// cardData may already exist if this originates from the PaymentRequest API so we allow either a
// jQuery $form element or the prebuilt card data
//
// in future we could extract the cardData building from $form elements to its own step
const tokenizeCard = function (
  endpointURL: string,
  form?: JQuery<HTMLFormElement>,
  cardData?: ICardData,
  paymentPlanID?: number,
): Promise<ITokenizeResult> {
  return new Promise<ICardData>((resolve, reject) => {
    if ((!form || form.length === 0) && !cardData) {
      reject(new Error('tokenizeCard requires either a form element or a cardData object'));
      return;
    }

    const cardNumberField = form.find('#checkout_card_number');
    const createReusableToken = !isNaN(paymentPlanID) && paymentPlanID > 0;
    const termsInput = form.find('#checkout_terms_accepted') as JQuery<HTMLInputElement>;
    const termsAccepted = termsInput.prop('checked');

    if (!cardData) {
      cardData = {
        name: getFormStringValue(form, '#checkout_name'),
        email: getFormStringValue(form, '#checkout_email'),
        phone: getFormStringValue(form, '#checkout_phone'),
        address1: getFormStringValue(form, '#checkout_address1'),
        city: getFormStringValue(form, '#checkout_city'),
        state: getFormStringValue(form, '#checkout_state'),
        zip: getFormStringValue(form, '#checkout_zip'),
        country: getFormStringValue(form, '#checkout_country'),
        card_number: getFormStringValue(form, '#checkout_card_number'),
        card_expires_on: getFormStringValue(form, '#checkout_card_expires_on'),
        card_verification: getFormStringValue(form, '#checkout_card_verification'),
        terms_accepted: termsAccepted,
        reusable: createReusableToken,
      };
    }

    // no CC field means free tickets so we can skip tokenization, deleting all the un-necessary
    // card fields in the process
    if (!cardNumberField || cardNumberField.length < 1) {
      const checkoutData = Object.assign({}, cardData);
      delete checkoutData.card_number;
      delete checkoutData.card_verification;
      delete checkoutData.card_expires_on;
      resolve(checkoutData);
      return;
    }

    cardData.reusable = createReusableToken;
    const xhr = jQuery.ajax({
      type: 'POST',
      url: endpointURL,
      data: cardData,
      dataType: 'json',
      xhrFields: {
        withCredentials: true,
      },
      headers: {
        Accept: 'application/json; charset=utf-8',
        'X-CSRF-Token': getCSRFToken(form[0]),
      },
    });

    xhr
      .then((result: ITokenizeResult) => {
        if (result.errors) {
          trackEvent('checkout', 'tokenize-invalid-error');
          reject(createErrorFromJSON(result));
          return;
        }

        // free tickets won't have a paysrc_id
        if (result.paysrc_id) {
          form.find('#checkout_paysrc_id').val(result.paysrc_id);
        }

        const checkoutData = Object.assign({}, cardData);
        delete checkoutData.card_number;
        delete checkoutData.card_verification;
        delete checkoutData.card_expires_on;
        checkoutData.paysrc_id = result.paysrc_id;
        checkoutData.payment_plan_id = createReusableToken ? paymentPlanID : undefined;
        console.debug('tokenizeCard ok, resolving with ', checkoutData);
        resolve(checkoutData);
      })
      .fail((xhr: JQuery.jqXHR) => {
        trackEvent('checkout', 'tokenize-server-error');
        reject(createErrorFromXHR(xhr));
      });
  });
};

export default tokenizeCard;
