import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const SeatmapHeader = (props) => {
  const handleClose = (event) => {
    event.preventDefault();
    props.handleClose();
  };

  const handleViewToggle = (event) => {
    event.preventDefault();
    const nextView = event.target.href.indexOf('#seatmap-map') > -1 ? 'map' : 'cart';
    props.handleViewToggle(nextView);
  };

  return (
    <div className="seatmap-header">
      <div className="seatmap-header-titles">
        <div className="seatmap-header-title">{props.eventTitle}</div>
      </div>
      <div className="seatmap-header-controls">
        <a
          className={classNames({
            'seatmap-cart-button': true,
            'btn-store': props.currentView === 'map',
            'seatmap-header-toggle': true,
          })}
          onClick={handleViewToggle}
          href="#seatmap-map"
        >
          Map View
        </a>

        <a
          className={classNames({
            'seatmap-cart-button': true,
            'btn-store': props.currentView === 'cart',
            'seatmap-header-toggle': true,
          })}
          onClick={handleViewToggle}
          href="#seatmap-cart"
        >
          Your Seats
        </a>
        <a className="seatmap-cart-button" onClick={handleClose}>
          Close
        </a>
      </div>
    </div>
  );
};

SeatmapHeader.propTypes = {
  eventTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleViewToggle: PropTypes.func.isRequired,
  currentView: PropTypes.oneOf(['cart', 'map']).isRequired,
};

SeatmapHeader.defaultProps = {
  currentView: 'cart',
};
